import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Tooltip,
  Box,
  IconButton,
} from "@material-ui/core";
import plus from "../../../../../assets/img/plus.svg";
import { Formik, useField } from "formik";
import * as Yup from "yup";
import { useStateAuthValue } from "../../../../../context/Auth/AuthState";
import { getIndicationsTemplate } from "../../../../../services/consultationService";
import Select from "react-select";
import DeleteIcon from "@material-ui/icons/Delete";

export default function IndicationTemplates({
  formField,
  parentValues,
  hasErrors,
  errors,
  setFieldValue,
  handleDisabled,
}) {
  const [{ userToken }] = useStateAuthValue();
  const [listIndicationTemplates, setListIndicationTemplates] = useState([]);

  const initialValues = {
    indicationsTemplateDTOList: [],
  };
  const [currentIndicationTemplate, setCurrentIndicationTemplate] =
    useState(null);
  const addIndicationTemplateToList = (values, setField) => {
    let newList = [currentIndicationTemplate];
    if (values.indicationsTemplateDTOList) {
      newList = [
        currentIndicationTemplate,
        ...values.indicationsTemplateDTOList,
      ];
    }

    setFieldValue("indicationsTemplateDTOList", newList);
    setField("indicationsTemplateDTOList", newList);
    setCurrentIndicationTemplate(null);
  };

  const removeIndicationTemplateFromList = (
    values,
    indicationTemplate,
    setField
  ) => {
    let newList = [];
    if (values.indicationsTemplateDTOList) {
      newList = values.indicationsTemplateDTOList.filter(
        (template) => template.id !== indicationTemplate.id
      );
    }
    setFieldValue("indicationsTemplateDTOList", newList);
    setField("indicationsTemplateDTOList", newList);
  };
  const MedicationSchema = Yup.object().shape({
    indicationsTemplateDTOList: Yup.array().nullable(),
  });

  const GetIndicationsTemplate = async () => {
    const errorMessage = "Ocurrió un error al traer las plantillas";
    await getIndicationsTemplate(userToken)
      .then((response) => {
        setListIndicationTemplates(response);
      })
      .catch(() => showError(errorMessage))
      .finally();
  };
  useEffect(() => {
    GetIndicationsTemplate();
  }, []);
  const filteredOptions = (currentList, baseList) => {
    const selectedIds = currentList.map((item) => item.id); // IDs seleccionados
    return baseList.filter((option) => !selectedIds.includes(option.id)); // Filtrar las no seleccionadas
  };
  useEffect(() => {
    handleDisabled(hasErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasErrors]);
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={MedicationSchema}
        validateOnMount
        enableReinitialize
      >
        {({
          values,
          setFieldValue: setField,
        }) => {
          return (
            <>
              <Grid item style={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <Grid item style={{ flexGrow: 1 }}>
                    <Select
                      name="currentIndicationTemplate"
                      options={filteredOptions(
                        values.indicationsTemplateDTOList,
                        listIndicationTemplates
                      )}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        setCurrentIndicationTemplate({
                          name: e.name,
                          id: e.id,
                        });
                      }}
                      value={
                        currentIndicationTemplate
                          ? {
                              label: currentIndicationTemplate.name,
                              id: currentIndicationTemplate.id,
                              name: currentIndicationTemplate.name,
                            }
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip title="Agregar medicamento">
                      <Button
                        width={40}
                        type="button"
                        fullWidth={false}
                        className="plus-button"
                        disabled={!currentIndicationTemplate}
                        onClick={() =>
                          addIndicationTemplateToList(values, setField)
                        }
                      >
                        <img src={plus} alt="plus" />
                      </Button>
                    </Tooltip>
                  </Grid>
                </Box>

                <Grid container spacing={2} item style={{ width: "100%" }}>
                  {values.indicationsTemplateDTOList.map((iTP) => {
                    return (
                      <Grid item xs={2.5}>
                        <Box
                          sx={{
                            boxShadow:
                              "0 10px 20px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04)",
                            borderRadius: "0 20px 20px 20px",
                            maxWidth: "250px",
                            fontWeight: "600",
                            display: "flex",
                            wordWrap: "break-word",
                            fontSize: ".8rem",
                            padding: "1rem",
                            alignItems: "center",
                          }}
                          id={iTP.id}
                        >
                          {iTP.name}
                          <Tooltip title={"Eliminar indicacion"}>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() =>
                                removeIndicationTemplateFromList(
                                  values,
                                  iTP,
                                  setField
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </>
          );
        }}
      </Formik>
    </div>
  );
}
